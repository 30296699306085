/* Custom styles */

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
    background-color: rgb(180, 180, 180);
}
