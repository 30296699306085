@media (max-width: 640px) {
}
@media (max-width: 768px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 1280px) {
    .scrollable-view {
        min-height: calc(100vh - 600px) !important;
        max-height: calc(100vh - 600px) !important;
        overflow: scroll !important;
    }
    .scrollable-xl-stop {
        overflow: inherit !important;
    }
}
@media (max-width: 1536px) {
}
